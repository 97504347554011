import React, { useReducer } from "react";
import axios from "axios";
import GithubContext from "./githubContext";
import GithubReducer from "./githubReducer";

import {
  SEARCH_USERS,
  SET_LOADING,
  GET_USERS,
  CLEAR_USERS,
  GET_USER,
  GET_REPOS
} from "../types";

let githubClientId;
let githubClientSecret;

if (process.env.NODE_ENV !== "production") {
  githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
  githubClientSecret = process.env.REACT_APP_GITHUB_CLIENT_SECRET;
} else {
  githubClientId = process.env.GITHUB_CLIENT_ID;
  githubClientSecret = process.env.GITHUB_CLIENT_SECRET;
}

const GithubState = props => {
  const initialState = {
    users: [],
    user: {},
    repos: [],
    loading: false
  };

  const [state, dispatch] = useReducer(GithubReducer, initialState);

  //search users
  const searchUsers = async text => {
    setLoading();

    const res = await axios.get(
      `https://api.github.com/search/users?q=${text}&client_id=${githubClientSecret}&client_secret=${githubClientId}`
    );

    dispatch({
      type: SEARCH_USERS,
      payload: res.data.items
    });
  };

  // get users
  const getUsers = async () => {
    setLoading();

    const res = await axios.get(
      `https://api.github.com/users?client_id=${githubClientSecret}&client_secret=${githubClientId}`
    );

    dispatch({
      type: GET_USERS,
      payload: res.data
    });
  };

  //get user
  const getUser = async login => {
    setLoading();

    const res = await axios.get(
      `https://api.github.com/users/${login}?client_id=${githubClientSecret}&client_secret=${githubClientId}`
    );
    dispatch({
      type: GET_USER,
      payload: res.data
    });
  };
  // get repos
  const getUserRepos = async login => {
    setLoading();

    const res = await axios.get(
      `https://api.github.com/users/${login}/repos?client_id=${githubClientSecret}&client_secret=${githubClientId}`
    );

    dispatch({
      type: GET_REPOS,
      payload: res.data
    });
  };
  // clear users
  const clearUsers = () => {
    dispatch({
      type: CLEAR_USERS
    });
  };
  // set loading
  const setLoading = () => dispatch({ type: SET_LOADING });
  return (
    <GithubContext.Provider
      value={{
        users: state.users,
        user: state.user,
        repos: state.repos,
        loading: state.loading,
        searchUsers,
        getUsers,
        getUser,
        clearUsers,
        getUserRepos
      }}
    >
      {props.children}
    </GithubContext.Provider>
  );
};

export default GithubState;
