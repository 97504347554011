import React, { useContext, useEffect } from "react";
import UserItem from "./UserItem";
import Spinner from "../layout/Spinner";
import GithubContext from "../../context/github/githubContext";

const Users = ({}) => {
  const githubContext = useContext(GithubContext);

  const { loading, users, getUsers } = githubContext;

  useEffect(() => {
    getUsers();
  }, []);

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div className="grid-3">
        {users.map(user => (
          <UserItem key={user.login} user={user}></UserItem>
        ))}
      </div>
    );
  }
};
export default Users;
