import React from "react";
import PropTypes from "prop-types";

const NotFound = props => {
  return (
    <div>
      <h1>Page not found</h1>
    </div>
  );
};

export default NotFound;
