import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import AlertContext from "../../context/alert/alertContext";

const Alert = () => {
  const alertContext = useContext(AlertContext);
  const { alert, showAlertMsg } = alertContext;
  return (
    alert !== null && (
      <div className={`aler alert-${alert.type}`}>
        <i className="fas fa-info-circle"></i> {alert.msg}
      </div>
    )
  );
};

export default Alert;
