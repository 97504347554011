import React from "react";
import PropTypes from "prop-types";

const RepoItem = props => {
  return (
    <div>
      <a href={props.repo.html_url}>{props.repo.description}</a>
    </div>
  );
};

RepoItem.propTypes = {};

export default RepoItem;
