import React from "react";

const About = props => {
  return (
    <div>
      <h1>About</h1>
      <p>This is Github Search Page</p>
      <p>Version 1.0.0</p>
    </div>
  );
};

About.propTypes = {};

export default About;
