import React, { useContext, useEffect, Fragment } from "react";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import Repos from "../repos/Repos";
import GithubContext from "../../context/github/githubContext";

const User = ({ match }) => {
  const githubContext = useContext(GithubContext);

  const { loading, user, getUser, getUserRepos, repos } = githubContext;
  useEffect(() => {
    getUser(match.params.login);
    getUserRepos(match.params.login);
  }, []);

  const {
    avatar_url,
    bio,
    blog,
    company,

    followers,

    following,

    hireable,

    location,
    login,
    name,

    public_gists,
    public_repos
  } = user;

  if (loading === true) {
    return <Spinner></Spinner>;
  }
  return (
    <Fragment>
      <Link to="/" className="btn btn-light">
        Back to home
      </Link>
      Hireable{" "}
      {hireable ? (
        <i className="fas fa-check text-success" />
      ) : (
        <i className="fas fa-times-circle text-danger" />
      )}
      <div className="card grid-2">
        <div className="all-center">
          <img
            className="round-img"
            style={{ width: "150px" }}
            src={avatar_url}
            alt=""
          />
          <h1>{name}</h1>
          <p>Location: {location}</p>
        </div>
        <div>
          {bio && (
            <Fragment>
              <h2>Bio</h2>
              <p>{bio}</p>
            </Fragment>
          )}
          <a href="{html_url}" className="btn btn-dark my-1">
            Github Profile
          </a>
          <ul>
            <li>
              {" "}
              {login && (
                <Fragment>
                  <strong>User Name</strong>
                  <p>{login}</p>
                </Fragment>
              )}
            </li>
            <li>
              {" "}
              {company && (
                <Fragment>
                  <strong>Company</strong>
                  <p>{company}</p>
                </Fragment>
              )}
            </li>
            <li>
              {" "}
              {blog && (
                <Fragment>
                  <strong>Blog</strong>
                  <p>{blog}</p>
                </Fragment>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="card text-center">
        <div className="badge badge-primary">Follower {followers}</div>
        <div className="badge badge-success">Following {following}</div>
        <div className="badge badge-light">Public Repos {public_repos}</div>
        <div className="badge badge-dark">Public Gists {public_gists}</div>
      </div>
      <Repos repos={repos}></Repos>
    </Fragment>
  );
};

export default User;
